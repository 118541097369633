// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mail-js": () => import("./../../../src/pages/mail.js" /* webpackChunkName: "component---src-pages-mail-js" */),
  "component---src-pages-workbook-vst-js": () => import("./../../../src/pages/workbook/vst.js" /* webpackChunkName: "component---src-pages-workbook-vst-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogposting-js": () => import("./../../../src/templates/blogposting.js" /* webpackChunkName: "component---src-templates-blogposting-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-musiccomposition-js": () => import("./../../../src/templates/musiccomposition.js" /* webpackChunkName: "component---src-templates-musiccomposition-js" */),
  "component---src-templates-musicrecording-js": () => import("./../../../src/templates/musicrecording.js" /* webpackChunkName: "component---src-templates-musicrecording-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

